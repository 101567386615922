import React from "react"
import Message from "../components/subscriber/message/message"
import Hero from "../sections/hero/hero"
import * as hero_styles from "../sections/hero/hero_index.module.scss"
import developerQueries from "../utils/choose-developer-queries"
import Layout from "../components/Layout"
import Subscriber from "../sections/subscriber/subscriber_section"
import ebook from "../images/finding.png"
import { getSeo } from "../utils/seo"

export default function HowToChooseADeveloper() {
  const { hero } = developerQueries()
  const content = {
    title: "Subscribe and receive our free checklist",
    description:
      "Are you trying to hire a software development agency? Subscribe and receive our awesome checklist. It will guide you on how to pick the perfect software developer!",
    url: "/thanks-for-subscribing-checklist",
    image: ebook,
  }

  return (
    <Layout>
      <Hero hero={hero} styles={hero_styles} nextSection={"message"} />
      <Subscriber content={content} />
    </Layout>
  )
}

export const Head = () => {
  const { seo } = developerQueries()
  return getSeo(seo)
}
